// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/services/patient.service.ts"
);
import.meta.hot.lastModified = "1730489002211.5767";
}
// REMIX HMR END

import { apiGet } from "./drupal.server.ts";
import {apiPost} from "./fetch.service.ts";
import merge  from "deepmerge";
import {isUndefined} from 'lodash'

export async function getPatientsData(request: Request, filters: any = {}) {
  const options  = merge(filters, {
      include: "facility,path,program,field_activities",
    })
  return await apiGet(request, 'nexus_patient--nexus_patient', options, true)
}

export async function getPatientData(request: Request, id: string) {
  let results = await apiGet(request, 'nexus_patient--nexus_patient', {
    filter: {
      id: id
    },
    page: {
      offset: 0,
      limit: 1,
    },
    include: "facility,path,program,field_activities,field_devices",
    fields: {
      "device--ventilator": "label,serial,remote_integration,remote_id"
    },
    'extra-filter': 'assessment_latest',
  }, false)
  if (isUndefined(results)) {
    return null
  }
  if (results.count == 0) {
    return null
  }
  return results.data[0]
}

export async function getPatientDataByEntityId(request: Request, id: string) {
	let results = await apiGet(
		request,
		'nexus_patient--nexus_patient',
		{
			filter: {
				drupal_internal__id: id,
			},
			page: {
				offset: 0,
				limit: 1,
			},
		},
		false,
	)
	if (isUndefined(results)) {
		return null
	}
	if (results.count == 0) {
		return null
	}
	return results.data[0]
}

export async function savePatientData(patientId: any, request: Request) {
  //TODO add format for saving patient
  return await apiPost('api/patient', request);
}

export async function getPatientPrograms(request: Request) {
  const results = await apiGet(request, 'taxonomy_term--program', {
    page: {
      offset: 0,
      limit: 50,
    },
    fields: {
      'taxonomy_term--program': 'name,drupal_internal__tid'
    }
  }, true)

  if (isUndefined(results)) {
    return null
  }
  if (results.count == 0) {
    return null
  }

  const programs = results.data.map((p) => {
    return {
      label: p.name,
      value: p.id,
      tid: p.drupal_internal__tid
    }
  })
  return programs
}

export const patient_status: any[] = [
  {
    label: 'Pending',
    value: 0,
    className: 'bg-red-500 text-white'
  },
  {
    label: 'Active',
    value: 1,
    className: 'bg-green-500 text-white'
  },
  {
    label: 'Discharged',
    value: 2,
    className: 'bg-slate-600 text-white'
  },
  {
    label: 'Readmitted',
    value: 3,
    className: 'bg-yellow-600 text-white'
  },
  {
    label: 'Opt Out',
    value: 4,
    className: 'bg-slate-700 text-white'
  },
  {
    label: 'Maintenance',
    value: 5,
    className: 'bg-purple-600 text-white'
  },
  {
    label: 'SNF',
    value: 6,
    className: 'bg-pink-600 text-white'
  },
  {
    label: 'Hospice',
    value: 7,
    className: 'bg-blue-600 text-white'
  }
]

export const patient_payors: any[] = [
  {
    label: 'None',
    value: 'none',
    className: ''
  },
  {
    label: 'Medicare',
    value: 'medicare',
    className: ''
  },
  {
    label: 'Medicare HMO/Advantage',
    value: 'medicare_2',
    className: ''
  },
  {
    label: 'Commercial PPO/Other',
    value: 'commercial',
    className: ''
  },
  {
    label: 'Medicaid',
    value: 'medicaid',
    className: ''
  },
  {
    label: 'Medicaid MCO',
    value: 'medicaid_2',
    className: ''
  },
  {
    label: 'Other Payor plan',
    value: 'other',
    className: ''
  },
]

export const patient_types: any[] = [
  {
    label: 'Other',
    value: 0,
    className: 'danger',
    bg: 'bg-red-600 text-white'
  },
  {
    label: 'Invasive',
    value: 1,
    className: 'warning',
    bg: 'bg-amber-400'
  },
  {
    label: 'NonInvasive',
    value: 2,
    className: 'info',
    bg: 'bg-violet-500 text-white'
  },
  {
    label: 'Oxygen',
    value: 3,
    className: 'success',
    bg: 'bg-blue-400 text-white'
  },
  {
    label: 'Trach',
    value: 4,
    className: '',
    bg: 'bg-lime-400 text-black'
  },
  {
    label: 'Vest',
    value: 5,
    className: '',
    bg: 'bg-stone-400 text-black'
  },
  {
    label: 'Nebulizer',
    value: 6,
    className: '',
    bg: ''
  },
  {
    label: 'PAP',
    value: 7,
    className: '',
    bg: ''
  },
  {
    label: 'BiLevel Trial',
    value: 8,
    className: '',
    bg: ''
  },
  {
    label: 'SNF based PIC',
    value: 9,
    className: '',
    bg: ''
  },
  {
    label: 'SNF based Long Term Care',
    value: 10,
    className: '',
    bg: ''
  },
]

